import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="body-font">
      <div className="container mx-auto flex flex-wrap pl-5 pt-24 md:flex-row flex-col items-center bg-header">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col mb-16 md:mb-0 items-center text-center mr-5">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            Stránka sa nenašla
          </h1>
          <div className="flex justify-center">
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
